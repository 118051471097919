import React, { useState, useEffect, useRef } from 'react';
import { Button,Grid,  RadioGroup, TextField, FormLabel, Radio,FormControlLabel, Checkbox } from '@mui/material';
import { Link } from 'react-router-dom'; 
import { useForm } from "react-hook-form";
import useFileUpload from 'react-use-file-upload';
import SignComponent from '../sign/SignComponent';
import { apiDocument } from '../../services/api/documents/Document';
import { apiProvider } from '../../services/api/utilities/Provider';
import {authVerify} from "../../services/api/utilities/AuthVerify";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate,useSearchParams } from 'react-router-dom';

const CreateComponent = (props) => {
    const { register,  getValues, setValue } = useForm();
    const [searchParams] = useSearchParams();
    const [validationMessage, setValidationMessage] = useState('');
    const [myItems, setMyItems] = useState([]);
    const [contacts, setContacts] = useState([]);
    const [groups, setGroups] = useState([]);
    const [role, setRole] = useState('');
    const [searchMyContacts, setSearchMyContacts] = useState([]);
    const [searchTheirContacts, setSearchTheirContacts] = useState([]);
    const [searchTheirGroupContacts, setSearchTheirGroupContacts] = useState([]);
    const [file, setFile] = useState(null);
    const [fileName, setFileName] = useState('');
    const [theirItems, setTheirItems] = useState([]);
    const [isMarkers, setIsMarkers] = useState(false);
    const [markers, setMarkers] = useState(0);
    const [isAddMyItem, setAddIsMyItem] = useState(false);
    const [isAddTheirItem, setAddIsTheirItem] = useState(false);
    const [isSearchMyItem, setSearchIsMyItem] = useState(false);
    const [isSignComponent, setIsSignComponent] = useState(false);
    const [isSearchTheirItem, setSearchIsTheirItem] = useState(false);
    const [isSearchTheirGroupItem, setSearchIsTheirGroupItem] = useState(false);
    const [model, setModel] = useState({items:[], theirItems:[], isFreeSort: true});
    const [classButton1, setClassButton1] = useState('button-sign');
    const [classButton2, setClassButton2] = useState('button-sign');
    const [classButton3, setClassButton3] = useState('button-sign');
    const [isLoading, setIsLoading] = useState(false);
    const [templateId, setTemplateId] = useState(null);
    const [template, setTemplate] = useState(null);
    const { t, i18n, ready } = useTranslation();
    let location = useLocation();

    useEffect(() => {
        authVerify.verifyUser();
        setClassButton2('button-sign-active');

        async function fetchData() {
            let items  = await apiDocument.contacts();
            let groups  = await apiDocument.groups();
            setContacts(items.items);
            setSearchMyContacts(items.items.filter(d=>!d.isTheir).slice(0, 5));
            setSearchTheirContacts(items.items.filter(d=>d.isTheir).slice(0, 5));
            setGroups(groups.items.slice(0, 5));
            setSearchTheirGroupContacts(groups.items.slice(0, 5));

            if (location.search.indexOf('templateId=') >= 0) {
                let id = searchParams.get('templateId');
                const template = await apiDocument.getTemplate(id);
                if(template)
                {
                    setTemplate(template);
                    setValue('name',template.name);
                }
                setTemplateId(id);
                setIsMarkers(true);
            }
        }

        fetchData();
        setRole(localStorage.getItem('role'));
    }, []);
    
    const {
        handleDragDropEvent,
      } = useFileUpload();

    const inputRef = useRef();

    const mySearch = async(query) => {        
        setSearchMyContacts(contacts.filter(d=>(d.email.toLowerCase().indexOf(query.toLowerCase())>=0 || (d.firstName+' '+d.lastName)===null || (d.firstName+' '+d.lastName).toLowerCase().indexOf(query.toLowerCase())>=0 || d.company===null || d.company.toLowerCase().indexOf(query.toLowerCase())>=0)).slice(0, 5));
    }

    const theirSearch = async(query) => {        
        let items = contacts.filter(d=>(d.email.toLowerCase().indexOf(query.toLowerCase())>=0 || (d.firstName+' '+d.lastName)==null || (d.firstName+' '+d.lastName).toLowerCase().indexOf(query.toLowerCase())>=0 || d.company===null || d.company.toLowerCase().indexOf(query.toLowerCase())>=0)).slice(0, 5);
        items = items.filter(d=>!theirItems.some(e=>e.email==d.email));
        setSearchTheirContacts(items);
    }

    const theirGroupSearch = async(query) => {        
        setSearchTheirGroupContacts(groups.filter(d=>d.name==null || d.name.toLowerCase().indexOf(query.toLowerCase())>=0).slice(0, 5));
    }

    const showPdf = async () => {
        if(templateId)
        {
            const template = await apiDocument.getTemplate(templateId);
            if(template)
            {
                const result = await apiDocument.generateDocument({htmlData: template.body})
                if(result && result.path)
                {
                    window.open(`${apiProvider.BASE_URL}${result.path}`, '_blank');
                }
            }
        }
        else
            window.open(`${apiProvider.BASE_URL}${file.fileName}`,'_blank');
    };

    const setFiles = (a) => {
        setTemplateId(null);
        setTemplate(null);
        let ev = a.target;
        if(a.dataTransfer!==null && a.dataTransfer!==undefined)
        {
            ev = a.dataTransfer;
        }
        apiDocument.uploadFile(ev.files[0]).then((file)=>{
            if(file!==null)
            {
                setValidationMessage('');
                if(file.data.error)
                {
                    setValidationMessage(t("create_text4c"));
                    return;
                }
                setFile(file.data);
                let oryginalFileName = file.data.oryginalFileName.replaceAll('"','');
                setFileName(oryginalFileName);
                setIsMarkers(file.data.isMarkers);
                setMarkers(file.data.markers);
                setValue('name',oryginalFileName);
            }
        });
    }

    const clickMyItem = (row) => {
        if(myItems.filter(d=>d.email==row.email).length>0)
        {
            setValidationMessage(t("create_text4b"));
            return;
        }
        if(theirItems.filter(d=>d.email==row.email).length>0)
        {
            setValidationMessage(t("create_text4b"));
            return;
        }
        if(theirItems.filter(d=>d.contacts && d.contacts.filter(e=>e.email==row.email).length>0).length>0)
        {
            setValidationMessage(t("create_text4b"));
            return;
        }

        setSearchIsMyItem(false);
        myItems.push({email: row.email, firstName: row.firstName, lastName: row.lastName, companyName: row.company, companyNumber: row.companyNumber});
    }

    const clickTheirItem = (row) => {
        if(myItems.filter(d=>d.email==row.email).length>0)
        {
            setValidationMessage(t("create_text4b"));
            return;
        }
        if(theirItems.filter(d=>d.email==row.email).length>0)
        {
            setValidationMessage(t("create_text4b"));
            return;
        }
        if(theirItems.filter(d=>d.contacts && d.contacts.filter(e=>e.email==row.email).length>0).length>0)
        {
            setValidationMessage(t("create_text4b"));
            return;
        }

        setSearchIsTheirItem(false);
        theirItems.push({email: row.email, firstName: row.firstName, lastName: row.lastName, companyName: row.company, companyNumber: row.companyNumber});
    }

    const clickTheirGroupItem = (row) => {
        theirItems.length=0;
        theirItems.push({email: '', firstName: 'Grupa: '+row.name, lastName: '', companyName: '', companyNumber: '', contacts:row.contacts});
        setTheirItems([...theirItems]);
        setSearchIsTheirGroupItem(false);
    }

    const addMyPerson = () => {
        let email = getValues("myEmail");
        let firstName = getValues("myFirstName");
        let lastName = getValues("myLastName");
        let myCompanyName = getValues("myCompanyName");
        let myCompanyNumber = getValues("myCompanyNumber");
        if(email===null || email==='')
        {
            setValidationMessage(t("create_text1"));
            return;   
        }
        if(email.indexOf('@')<0)
        {
            setValidationMessage(t("create_text2"));
            return;   
        }
        if(firstName===null || firstName==='')
        {
            setValidationMessage(t("create_text3"));
            return;   
        }
        if(lastName===null || lastName==='')
        {
            setValidationMessage(t("create_text4"));
            return;   
        }
        if(myItems.filter(d=>d.email==email).length>0)
        {
            setValidationMessage(t("create_text4b"));
            return;
        }
        if(theirItems.filter(d=>d.email==email).length>0)
        {
            setValidationMessage(t("create_text4b"));
            return;
        }
        setValidationMessage('');
        myItems.push({email: email, firstName: firstName, lastName: lastName, companyName: myCompanyName, companyNumber: myCompanyNumber});
        setMyItems([...myItems]);
        setAddIsMyItem(false);
        setSearchIsMyItem(false);
        setValue('myEmail','');
        setValue('myFirstName','');
        setValue('myLastName','');
        setValue('myCompanyName','');
        setValue('myCompanyNumber','');
    }

    const addTheirPerson = () => {
        let email = getValues("theirEmail"); 
        let firstName = getValues("theirFirstName");
        let lastName = getValues("theirLastName");
        let myCompanyName = getValues("theirCompanyName");
        let myCompanyNumber = getValues("theirCompanyNumber");
        if(email===null || email==='')
        {
            setValidationMessage(t("create_text5"));
            return;   
        }
        if(email.indexOf('@')<0)
        {
            setValidationMessage(t("create_text6"));
            return;   
        }
        if(firstName===null || firstName==='')
        {
            setValidationMessage(t("create_text7"));
            return;   
        }
        if(lastName===null || lastName==='')
        {
            setValidationMessage(t("create_text8"));
            return;   
        }
        if(myItems.filter(d=>d.email==email).length>0)
        {
            setValidationMessage(t("create_text4b"));
            return;
        }
        if(theirItems.filter(d=>d.email==email).length>0)
        {
            setValidationMessage(t("create_text4b"));
            return;
        }
        if(theirItems.filter(d=>d.contacts && d.contacts.filter(e=>e.email==email).length>0).length>0)
        {
            setValidationMessage(t("create_text4b"));
            return;
        }
        setValidationMessage('');
        theirItems.push({email: email, firstName: firstName, lastName: lastName, companyName: myCompanyName, companyNumber: myCompanyNumber});
        setTheirItems([...theirItems]);
        setAddIsTheirItem(false);
        setSearchIsTheirItem(false);
        setValue('theirEmail','');
        setValue('theirFirstName','');
        setValue('theirLastName','');
        setValue('theirCompanyName','');
        setValue('theirCompanyNumber','');
    }

    const addMyClick = () => {
        setAddIsMyItem(true);
    }

    const searchMyClick = () => {
        setSearchIsMyItem(true);
    }

    const searchTheirClick = () => {
        theirSearch('');
        setSearchIsTheirItem(true);
    }

    const searchTheirGroupClick = () => {
        setSearchIsTheirGroupItem(true);
    }

    const closeMyClick = () => {
        setValue('myEmail','');
        setValue('myFirstName','');
        setValue('myLastName','');
        setValue('myCompanyName','');
        setValue('myCompanyNumber','');
        setAddIsMyItem(false);
        setSearchIsMyItem(false);
    }

    const addTheirClick = () => {
        setAddIsTheirItem(true);
    }

    const closeTheirClick = () => {
        setValue('theirEmail','');
        setValue('theirFirstName','');
        setValue('theirLastName','');
        setValue('theirCompanyName','');
        setValue('theirCompanyNumber','');
        setAddIsTheirItem(false);
        setSearchIsTheirItem(false);
    }

    const closeTheirGroupClick = () => {
        setSearchIsTheirGroupItem(false);
    }

    const removeTheirClick = (row) => {
        let index = theirItems.indexOf(row);
        theirItems.splice(index, 1);
        setTheirItems([...theirItems]);
    }

    const removeMyClick = (row) => {
        let index = myItems.indexOf(row);
        myItems.splice(index, 1);
        setMyItems([...myItems]);
    }

    const setSignOrder = (order) => {
        model.signOrder = order;
        setModel(model)
    }

    const setSignMyOrder = (order) => {
        model.signMyOrder = order!=="1";
        setModel(model)
    }

    const setSignTheirOrder = (order) => {
        model.signTheirOrder = order!=="1";
        model.isFreeSort = order==="1";
        setModel(model)
    }

    const changeKIR = (row, isKIR) => {
        row.isKIR = isKIR;
    }

    const submitDocument = async () => 
    {   
        if(!isLoading)
        {
            setIsLoading(true);
            if(file===null && !templateId)
            {
                setValidationMessage(t("create_text9"));
                setIsLoading(false);
                return;   
            }
            if(getValues("name")===null || getValues("name")==='')
            {
                setValidationMessage(t("create_text10"));
                setIsLoading(false);
                return;   
            }
            if(isMarkers && markers!==theirItems.length+myItems.length && !templateId)
            {
                setValidationMessage(t("create_text10b"));
                setIsLoading(false);
                return;   
            }

            if(templateId)
            {
                let countMarkers = 0;
                const template = await apiDocument.getTemplate(templateId);
                if(template && template.body)
                {
                    if(template.body.indexOf('##SignsafeMy1##')>=0)
                    {
                        countMarkers++;
                    }
                    if(template.body.indexOf('##SignsafeMy2##')>=0)
                    {
                        countMarkers++;
                    }
                    if(template.body.indexOf('##SignsafeMy3##')>=0)
                    {
                        countMarkers++;
                    }
                    if(template.body.indexOf('##SignsafeMy4##')>=0)
                    {
                        countMarkers++;
                    }
                    if(template.body.indexOf('##SignsafeMy5##')>=0)
                    {
                        countMarkers++;
                    }

                    if(template.body.indexOf('##SignsafeTheir1##')>=0)
                    {
                        countMarkers++;
                    }
                    if(template.body.indexOf('##SignsafeTheir2##')>=0)
                    {
                        countMarkers++;
                    }
                    if(template.body.indexOf('##SignsafeTheir3##')>=0)
                    {
                        countMarkers++;
                    }
                    if(template.body.indexOf('##SignsafeTheir4##')>=0)
                    {
                        countMarkers++;
                    }
                    if(template.body.indexOf('##SignsafeTheir5##')>=0)
                    {
                        countMarkers++;
                    }
                    if(countMarkers!=theirItems.length+myItems.length)
                    {
                        setValidationMessage(t("create_text10b"));   
                        setIsLoading(false);
                        return;   
                    }
                }
            }
            
            if(theirItems.length+myItems.length===0)
            {
                setValidationMessage(t("create_text11"));
                setIsLoading(false);
                return;   
            }

            theirItems.forEach(element => {
                element.contacts=null;
            });

            model.name = getValues('name');
            
            if(!model.signOrder)
            {
                model.signOrder=2;
            }
            if(!model.signMyOrder)
            {
                model.signMyOrder=false;
            }
            if(!model.signTheirOrder)
            {
                model.signTheirOrder=false;
            }
            model.isMarkers = isMarkers;
            model.templateId = templateId;
            setModel(model);
            setIsLoading(false);
            setIsSignComponent(true);
        }
    }

    return (
        <div>
            {!isSignComponent && <div className="main-body">
                    {(role==='2' || role==='3') && 
                        <Grid container>
                            <Grid item xs={6}>
                                <a className="second-tab">{t("create_text12")}</a>
                            </Grid>
                            <Grid item xs={6}>
                                <Link to="/index" className="first-tab">{t("create_text13")}</Link>
                            </Grid>
                        </Grid>
                    }
                    {(role!=='2' && role!=='3') && 
                        <Grid container>
                            <Grid item xs={12}>
                                <Link to="/index" className="first-tab">{t("create_text14")}</Link>
                            </Grid>
                        </Grid>
                    }
                    <Grid container className="padding-20">
                        <Grid item>
                            <p className='box-signers-radio-info'><span className="number">1. </span>Wybierz dokument lub wzorzec do wysłania {!file && !template && <i className='approve2'></i>}{(file || template) && <i className='approve'></i>}</p>
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item className="upload-file-box" style={{width: "1030px", maxWidth:"90%"}}>
                            {file===null && !templateId && <div className="file-box blink"
                                onDragEnter={handleDragDropEvent}
                                onDragOver={handleDragDropEvent}
                                onDrop={(e) => {
                                    handleDragDropEvent(e);
                                    setFiles(e, 'a');
                                }}
                                >
                                <div className="file-add" onClick={() => inputRef.current.click()}></div>
                                <p className="file-box-uploaded"><a onClick={() => inputRef.current.click()}>{t("create_text15")}</a>&nbsp;<span>{t("create_text15c")}</span>&nbsp;<a onClick={e=>props.setIsOpenTemplates(true)}>{t("create_text15g")}</a></p>
                                <p className="file-box-text">{t("create_text15e")}</p>
                                {/* Hide the crappy looking default HTML input */}
                                <input
                                    ref={inputRef}
                                    type="file"
                                    multiple
                                    accept="application/pdf"
                                    style={{ display: 'none' }}
                                    onChange={(e) => {
                                    setFiles(e, 'a');
                                    inputRef.current.value = null;
                                    }}/>
                            </div>
                            }
                            {(file!==null || templateId) && <div className="file-box"
                                onDragEnter={handleDragDropEvent}
                                onDragOver={handleDragDropEvent}
                                onDrop={(e) => {
                                    handleDragDropEvent(e);
                                    setFiles(e, 'a');
                                }}
                                >

                                {!templateId && <p className="file-box-name">{fileName}</p>}
                                {templateId && <p className="file-box-name">{t("create_text15f")}: {template.name}</p>}
                                {templateId && <p className="file-box-uploaded"><a onClick={() => inputRef.current.click()}>{t("create_text15b")}</a><span>&nbsp;{t("create_text15c")}&nbsp;</span><a onClick={e=>props.setIsOpenTemplates(true)}>{t("create_text15d")}</a></p>}
                                {!templateId && <p className="file-box-uploaded" onClick={() => inputRef.current.click()}>{t("create_text16")}</p>}

                                {/* Hide the crappy looking default HTML input */}
                                <input
                                    ref={inputRef}
                                    type="file"
                                    multiple
                                    style={{ display: 'none' }}
                                    onChange={(e) => {
                                        setFiles(e, 'a');
                                        inputRef.current.value = null;
                                    }}/>
                            </div>
                            }
                        </Grid>
                        <Grid item xs className="right-element">
                            {file===null && !templateId && 
                                <div className='show-pdf' onClick={()=>showPdf()}>
                                    <span>{t("create_text17")}</span>
                                </div>
                            }
                            {(file!==null || templateId) && 
                                <div className='white-show-pdf' onClick={()=>showPdf()}>
                                    <span>{t("create_text17")}</span>
                                </div>
                            }
                        </Grid>
                    </Grid>
                    {validationMessage && <Grid container>
                        <div className="validation-error validation-document">{validationMessage}</div>
                    </Grid>
                    }
                    <Grid container className="padding-20">
                        <FormLabel>{t("create_text18")}</FormLabel>
                        <TextField autoComplete='off' required autoFocus {...register("name", { required: true })} /><br />
                    </Grid>
                    <Grid container className="border-grid" style={{opacity: (file || template) ? 1:0.2}}>
                        <Grid item xs={12} className="border-second">
                            <Grid container className="padding-20">
                                <Grid item>
                                    <p className='box-signers-radio-info'><span className="number">2. </span>Wybierz osoby które mają podpisać dokument {theirItems.length<=0 && <i className='approve2'></i>}{theirItems.length>0 && <i className='approve'></i>}</p>
                                </Grid>
                            </Grid>
                            <Grid container className="box-signers">
                                <Grid item xs={12}>
                                    <h4>{t("create_text32b")}</h4>
                                </Grid>
                                {theirItems.map((row, index) => {
                                    return(<Grid item xs={12} className={`box-item ${"box-item"+(index+4)}`}>
                                        <Grid container>
                                            <Grid item xs={11} className="only-mobile">
                                                {row.firstName.indexOf('Grupa: ')<0  && <p>{row.firstName+' '+row.lastName+'-'+row.companyName+'-'+row.companyNumber}<br/>{row.email}</p>}
                                                {row.firstName.indexOf('Grupa: ')>=0  && <p>{row.firstName}<br/></p>}
                                            </Grid>
                                            <Grid item xs={6} className="not-mobile">
                                                {row.firstName.indexOf('Grupa: ')<0  && <p>{row.firstName+' '+row.lastName+'-'+row.companyName+'-'+row.companyNumber}<br/>{row.email}</p>}
                                                {row.firstName.indexOf('Grupa: ')>=0  && <p>{row.firstName}<br/></p>}
                                            </Grid>
                                            {<Grid item xs={5} className="not-mobile">
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox color='primary' onChange={e=>changeKIR(row, e.target.checked)} />
                                                    }
                                                    label={t("create_text40b")}
                                                />
                                            </Grid>}
                                            <Grid item xs={1} className="right-element">
                                                <a className="x" onClick={()=>{removeTheirClick(row)}}></a>
                                            </Grid>
                                        </Grid>
                                    </Grid>);
                                })}
                                {!isAddTheirItem && !isSearchTheirItem && !isSearchTheirGroupItem && <Grid item xs={12}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={8} className="not-mobile">
                                            <Button className="button width98 button-little-small" disabled={!(file || template)} onClick={()=>{addTheirClick()}}>{t("create_text33")}</Button>
                                        </Grid>
                                        <Grid item xs={2} className="not-mobile">
                                            <Button className="button-person width98" disabled={!(file || template)} onClick={()=>{searchTheirClick()}}>{t("create_text34")}</Button>
                                        </Grid>
                                        <Grid item xs={2} className="not-mobile">
                                            <Button className="button-person width98" disabled={!(file || template)} onClick={()=>{searchTheirGroupClick()}}>{t("create_text34b")}</Button>
                                        </Grid>

                                        <Grid item xs={12} className="only-mobile">
                                            <Button className="button button-little-small" disabled={!(file || template)} onClick={()=>{addTheirClick()}}>{t("create_text33")}</Button>
                                        </Grid>
                                        <Grid item xs={6} className="only-mobile">
                                            <Button className="button-person width98" disabled={!(file || template)} onClick={()=>{searchTheirClick()}}>{t("create_text34")}</Button>
                                        </Grid>
                                        <Grid item xs={6} className="only-mobile">
                                            <Button className="button-person width98" disabled={!(file || template)} onClick={()=>{searchTheirGroupClick()}}>{t("create_text34b")}</Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                }
                                {isAddTheirItem && <Grid item xs={12}>
                                        <Grid container className="add-person-box">
                                            <Grid item xs={12}>
                                                <Grid container spacing={1}>
                                                    <Grid item xs={11}>
                                                        <h5>{t("create_text35b")}</h5>
                                                    </Grid>
                                                    <Grid item xs={1}  className="right-element">
                                                        <a className="x" onClick={()=>closeTheirClick()}></a>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField autoComplete='off' autoFocus {...register("theirEmail", { required: true })} placeholder={t("create_text35")}/>
                                            </Grid>
                                            <Grid container spacing={1}>
                                                <Grid item xs={6}>
                                                    <TextField autoComplete='off' {...register("theirFirstName", { required: true })} placeholder={t("create_text36")}/>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <TextField autoComplete='off' {...register("theirLastName", { required: true })} placeholder={t("create_text37")}/>
                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={1}>
                                                <Grid item xs={6}>
                                                    <TextField autoComplete='off' {...register("theirCompanyName", { required: true })} placeholder={t("create_text38")}/>
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <TextField autoComplete='off' {...register("theirCompanyNumber", { required: true })} placeholder={t("create_text39")}/>
                                                </Grid>
                                            </Grid>
                                            <Grid container spacing={1}>
                                                <Grid item xs>
                                                </Grid>
                                                <Grid item xs={6} style={{textAlign:"right"}}>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox {...register("theirSave")} color='primary' defaultChecked/>
                                                    }
                                                    label={t("create_text40")}
                                                />
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Button className="button-person width98 blink" onClick={()=>addTheirPerson()}>{t("create_text41")}</Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                }                        
                                {isSearchTheirItem && <Grid item xs={12}>
                                        <Grid container className="add-search-box">
                                            <Grid item xs={12}>
                                                <Grid container spacing={1}>
                                                    <Grid item xs={4}>
                                                        <h5>{t("create_text42")}</h5>
                                                    </Grid>
                                                    <Grid item xs={7}>
                                                        <i className="search"></i>
                                                        <TextField autoComplete='off' placeholder={t("create_text42b")} className='search-input' {...register("theirSearch",{onChange: (e) => theirSearch(e.target.value)})}/>
                                                    </Grid>
                                                    <Grid item xs={1} className="right-element">
                                                        <a className="x" onClick={()=>closeTheirClick()}></a>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            {searchTheirContacts.map((row, index) => {
                                                return(
                                                    <Grid item xs={12} key={index} className="add-search-box-item" onClick={()=>clickTheirItem(row)}>
                                                        <Grid container spacing={1}>
                                                            <Grid item xs={12}>
                                                                <p>{row.firstName+' '+row.lastName+' - '+row.company+' - '+row.companyNumber+' - '+row.email}</p>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>);
                                            })}
                                        </Grid>
                                    </Grid>
                                }
                                {isSearchTheirGroupItem && <Grid item xs={12}>
                                        <Grid container className="add-search-box">
                                            <Grid item xs={12}>
                                                <Grid container spacing={1}>
                                                    <Grid item xs={4}>
                                                        <h5>{t("create_text42c")}</h5>
                                                    </Grid>
                                                    <Grid item xs={7}>
                                                        <i className="search"></i>
                                                        <TextField autoComplete='off' placeholder={t("create_text42c")} className='search-input' {...register("theirGroupSearch",{onChange: (e) => theirGroupSearch(e.target.value)})}/>
                                                    </Grid>
                                                    <Grid item xs={1} className="right-element">
                                                        <a className="x" onClick={()=>closeTheirGroupClick()}></a>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            {searchTheirGroupContacts.map((row, index) => {
                                                return(
                                                    <Grid item xs={12} key={index} className="add-search-box-item" onClick={()=>clickTheirGroupItem(row)}>
                                                        <Grid container spacing={1}>
                                                            <Grid item xs={12}>
                                                                <p>{row.name}</p>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>);
                                            })}
                                        </Grid>
                                    </Grid>
                                }
                                <Grid item xs={12}>
                                    <Grid container>
                                        <p className="box-signers-radio-info">{t("create_text43")}</p>
                                    </Grid>
                                    <Grid container>
                                        <RadioGroup className="radio" defaultValue="1" onChange={(event)=>setSignTheirOrder(event.target.value)}>
                                            <FormControlLabel value="1" control={<Radio />} label={t("create_text44")} disabled={!(file || template)}/>
                                            <FormControlLabel value="2" control={<Radio />} label={t("create_text45")} disabled={!(file || template)}/>
                                        </RadioGroup>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container alignItems="center" justifyContent="center" style={{opacity: (file || template) && theirItems.length>0 ? 1:0.2}}>
                        <Grid item style={{width:"280px"}}>
                            <Button className="button" onClick={()=>submitDocument()} fullWidth disabled={!(file || template) || theirItems.length<=0}>
                                {isLoading && 
                                    <span className="loader"></span>
                                }
                                {!isLoading && 
                                    <span>{t("create_text50")}</span>
                                }
                            </Button>
                        </Grid>
                    </Grid>
                    <br/>
                </div>
            }
            {isSignComponent && 
                <div>
                    <SignComponent myItems={myItems} theirItems={theirItems} setIsSignComponent={setIsSignComponent} model={model} file={file} templateId={templateId}></SignComponent>
                </div>
            }
        </div>
    );
}

export default CreateComponent;